<template>
  <div class="content" >
    <Card title="回调参数填写">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="选择授权平台:" prop="platform">
          <Select v-model="formValidate.platform" placeholder="授权平台">
            <Option v-for="item in allPlatform" :value="item.value" :key="item.value">{{item.label}}</Option>
          </Select>
        </FormItem>

        <FormItem label="罗盘账号:" prop="isCompass">
          <RadioGroup v-model="formValidate.isCompass">
            <Radio label="yes">是</Radio>
            <Radio label="no">否</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="选择授权应用:" prop="packageName">
          <Select v-if="formValidate.isCompass==='no'" v-model="formValidate.packageName" filterable placeholder="授权应用">
            <Option v-for="item in allApp" :value="item.app_name" :key="item.app_name">{{item.app}}</Option>
          </Select>

          <Select v-if="formValidate.isCompass==='yes'" disabled placeholder="未知应用"></Select>
        </FormItem>

        <FormItem label="授权账号ID:" prop="accountID">
          <Input v-model="formValidate.accountID" placeholder="输入账号ID"></Input>
        </FormItem>

        <FormItem label="上传增长参谋:" prop="isUpload">
          <RadioGroup v-if="formValidate.isCompass==='no'" v-model="formValidate.isUpload">
            <Radio label="yes">是</Radio>
            <Radio label="no">否</Radio>
          </RadioGroup>

          <RadioGroup v-if="formValidate.isCompass==='yes'" v-model="formValidate.isUpload">
            <Radio label="yes" disabled>是</Radio>
            <Radio label="no" disabled>否</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem v-if="formValidate.isUpload ==='yes'" label="增长参谋-APPID">
          <Input v-model="formValidate.csjAppID" placeholder="输入穿山甲AppID"/>
        </FormItem>

        <FormItem>
          <Row :gutter="16">
            <Col span="6">
              <Button type="primary" @click="handleSubmit('formValidate')">Submit</Button>
            </Col>
            <Col span="6">
              <Button @click="handleReset()" style="margin-left: 8px">Reset</Button>
            </Col>
            <Col span="6" v-if="formValidate.platform === 'kuaishou'">
              <Button type="success" @click="handleJump()">授权链接跳转</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Card>
    <Modal
        v-model="isModelAuth"
        title="授权链接跳转"
        @on-ok="authOk"
        @on-cancel="authCancel">
      <p>
        请确保磁力引擎登录的快手ID是: <b>{{formValidate.accountID}}</b>
      </p>
    </Modal>
    <Modal
        v-model="isModelSubmit"
        title="确认提交"
        @on-ok="submitOk"
        @on-cancel="submitCancel">
      <List>
        <ListItem>选择授权平台:<b>{{platformName[formValidate.platform]}}</b></ListItem>
        <ListItem>罗盘账号:<b>{{selectMap[formValidate.isCompass]}}</b></ListItem>
        <ListItem>选择授权应用:<b>{{appMap[formValidate.packageName]}}</b></ListItem>
        <ListItem>授权账号ID:<b>{{formValidate.accountID}}</b></ListItem>
        <ListItem>上传增长参谋:<b>{{selectMap[formValidate.isUpload]}}</b></ListItem>
        <ListItem v-if="formValidate.csjAppID !== ''">增长参谋-APPID:<b>{{formValidate.csjAppID}}</b></ListItem>
      </List>
    </Modal>
  </div>
</template>

<script>
import {Callback,GetAppList} from "@/api/callback";
export default {
  name: 'Callback',
  data () {
    return {
      appMap:{},
      selectMap:{
        "yes":"是",
        "no":"否"
      },
      platformName:{
        "kuaishou":"快手",
        "toutiao":"头条",
      },
      isModelSubmit:false,
      isModelAuth:false,
      isSubmit: false,
      platformMap:{
        "kuaishou":165897661,
        "toutiao":1722532901119006,
      },

      allPlatform:[
        {
          value: 'kuaishou',
          label: '快手'
        },
        {
          value: 'toutiao',
          label: '头条'
        }
      ],
      allApp:[],
      formValidate: {
        platform: '',
        packageName:'unknown',
        accountID:'',
        isUpload:'no',
        isCompass:'no',
        csjAppID:'',
      },
      ruleValidate: {
        platform: [
          {required: true, message: '授权平台不能为空', trigger: 'change'}
        ],
        packageName: [
          {required: true, message: '授权应用不能为空', trigger: 'change'}
        ],
        accountID: [
          {required: true, message: '账号ID不能为空', trigger: 'blur'}
        ],
        isUpload: [
          {required: true, message: '选择上传增长参谋', trigger: 'change'}
        ],
        isCompass: [
          {required: true, message: '选择罗盘', trigger: 'change'}
        ],
      }
    }
  },
  created() {
    GetAppList().then(res=>{
      if(res.data === "获取应用失败"){
        this.$Message.error(res.data);
        this.allApp.push({"app":"未知应用","app_name":"unknown"})
        for (let item of this.allApp) {
          this.appMap[item.app_name] = item.app
        }
        return
      }
      this.allApp = res.data
      this.allApp.push({"app":"未知应用","app_name":"unknown"})
      for (let item of this.allApp) {
        this.appMap[item.app_name] = item.app
      }
    })
  },
  methods: {
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.isModelSubmit = true
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset () {
      this.formValidate.platform = ''
      this.formValidate.packageName = 'unknown'
      this.formValidate.accountID = ''
      this.formValidate.isUpload = 'no'
      this.formValidate.isCompass = 'no'
      this.formValidate.csjAppID = ''
    },
    handleJump(){
      if(this.isSubmit === false){
        this.$Message.error("跳转之前请先提交！！！");
        return
      }
      this.isModelAuth = true
    },
    authOk(){
      let packageName = this.formValidate.packageName
      if(packageName === "unknown"){
        packageName = ""
      }

      let url = `https://developers.e.kuaishou.com/tools/authorize?app_id=` + this.platformMap[this.formValidate.platform]
      url += `&scope=%5B%22ad_query%22%2C%22ad_manage%22%2C%22report_service%22%2C%22account_service%22%2C%22public_dmp_service%22%2C%22public_agent_service%22%2C%22public_account_service%22%5D&redirect_uri=`
      url += `https://mtasks.dev.tagtic.cn/api/marketing/callback/ks&state={"app_id":`+this.platformMap[this.formValidate.platform] + ","
      url += `"package_name":"` + packageName + `","account_id":`+this.formValidate.accountID+"}"
      window.location.href = url
    },
    authCancel(){
      this.isModelAuth = false
    },
    submitOk(){
      let accountId = parseInt(this.formValidate.accountID)
      if(isNaN(accountId)){
        this.$Message.error("授权账号ID填写错误，授权账号ID是int类型");
        return
      }

      let csjAppID = 0
      if(this.formValidate.csjAppID !== ''){
        csjAppID = parseInt(this.formValidate.accountID)
        if(isNaN(csjAppID)){
          this.$Message.error("增长参谋-APPID填写错误，增长参谋-APPID是int类型");
          return
        }
      }
      if(this.formValidate.isUpload === 'yes'&&this.formValidate.packageName==="unknown"){
        this.$Message.error("上传增长参谋，授权应用必须选择");
        return
      }

      let isUpload = false
      if(this.formValidate.isUpload === 'yes'){
        isUpload = true
      }

      let isCompass = false
      if(this.formValidate.isCompass === 'yes'){
        isCompass = true
      }

      let packageName = this.formValidate.packageName
      if(packageName === "unknown"){
        packageName = ""
      }

      let data = {
        "app_id":this.platformMap[this.formValidate.platform],
        "platform":this.formValidate.platform,
        "package_name":packageName,
        "account_id":accountId,
        "is_upload": isUpload,
        "is_compass":isCompass,
        "csj_app_id":csjAppID,
      }

      Callback(data).then(res=>{
        if(res.data === "操作成功"){
          this.isSubmit = true
          this.$Message.info(res.data);
        }else{
          this.$Message.error(res.data);
        }
      })
    },
    submitCancel(){
      this.isModelSubmit = false
    },
  },
  watch:{
    // 罗盘账号，没有包名，不能上传
    "formValidate.isCompass": function (newData){
      if(newData === 'no'){
        this.formValidate.packageName = 'unknown'
      }else{
        this.formValidate.isUpload = 'no'
        this.formValidate.packageName = 'unknown'
      }
    },
    "formValidate.isUpload":function (newData){
      if(newData === 'no'){
        this.formValidate.csjAppID = ''
      }
    },
    "formValidate":{
      handler:function (){
        this.isSubmit = false
      },
      deep:true
    }
  }
}
</script>

<style>
.content{
  text-align: center;
  background-color: #FFFF;
  width: 30%;
  margin: auto;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:2;
  position: absolute;
}
b{
  color: #dc2514;
}
</style>
