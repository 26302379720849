import axios from 'axios'

axios.defaults.withCredentials = true// Cookie跨域

// 导出请求类
export default class HttpRequest {
    // 设置默认请求参数
    defaultHeaders() {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                // 'Content-Type': 'application/json;charset=UTF-8'
            }
        };
    }

    // axios 请求，method，url，data
    request(options) {
        // 创建 axios请求
        const instance = axios.create({
            baseURL:"https://mtask-job.xg.tagtic.cn/"
        });

        // 覆盖默认
        options = Object.assign(this. defaultHeaders(), options);

        // 发送请求
        return instance(options)
    }
}
